<template>
  <Layout>
    <div>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <!-- Right Sidebar -->
        <div class="col-12">
          <mailSideBar />
          <div class="email-rightbar mb-3">
            <div class="card">
              <div class="btn-toolbar p-3">
                <Toolbar />
              </div>
              <div class="mt-3">
                <ul class="message-list">
                  <li v-for="(mail, index) in mails" :key="index" >
                    <div class="col-mail col-mail-1">
                      <div class="checkbox-wrapper-mail">
                        <input :id="`chk-${index}`" type="checkbox" />
                        <label :for="`chk-${index}`"></label>
                      </div>

                      <a class="title" v-if="mail.toArray.length !== 0" href="javascript: void(0);" v-on:click="$router.push({path:'/email/reademail/'+mail._id+'/'+$route.name})">{{ mail.toArray[0].email_address }},+{{ findLen(mail.toArray) }}</a>
                      <a class="title" v-if="mail.all" href="javascript: void(0);" v-on:click="$router.push({path:'/email/reademail/'+mail._id+'/'+$route.name})">{{ mail.all }}</a>
                    </div>
                    <div class="col-mail col-mail-2">
                      <a class="subject" href="javascript: void(0);" v-on:click="$router.push({path:'/email/reademail/'+mail._id+'/'+$route.name})">{{ mail.subject }}</a>
                      <div class="date">{{ convertDate(mail.createdAt) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-md-between align-items-md-center">
              <div class="col-xl-7">Showing {{ startIndex }} - {{ endIndex }} of {{ rows }}</div>
              <!-- end col-->
              <div class="col-xl-5">
                <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    @input="onPageChange"></b-pagination>
                </div>
              </div>
              <!-- end col-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import mailSideBar from "@/components/email-components/mailSideBar";
import Toolbar from "@/components/email-components/toolbar";
import PageHeader from "@/components/page-header";
import Layout from "../../layouts/main";





export default {
  page: {
    title: "Inbox",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader, mailSideBar, Toolbar },
  data() {
    return {

      paginatedEmailData: null,
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/email"
        },
        {
          text: "Sent Mails",
          active: true
        }
      ],

      // page number
      currentPage: 1,
      // default page size
      perPage: 15,

      // start and end index
      startIndex: 1,
      endIndex: 15,
      showModal: false,
    };
  },
  computed: {
    mails() {
      return this.$store.getters['emails/sentMailsGetter']
    },

    rows() {
      return this.mails.length;
    }
  },
  created() {
    this.startIndex = 0;
    this.endIndex = this.perPage;

    this.paginatedEmailData = this.emailData.slice(
      this.startIndex,
      this.endIndex
    );
  },
  methods: {
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      this.paginatedEmailData = this.emailData.slice(
        this.startIndex,
        this.endIndex
      );
    },

    findLen(arr){
      return arr.length-1
    },
    
    convertDate(date) {
      let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return newDate
    },

  },

  mounted() {
    this.$store.dispatch('emails/getSentMails')

  }
};
</script>

<style scoped></style>