import axios from "axios"

const axios2 = axios.create({
    baseURL: 'https://live.rydelinx.com/api/admin/email/',
    headers: {
      'x-api-key': 'Wx8Cc7rEDJXTcyPfzM65h5q0AKDjLXqb'
    }
  })
export default {
    namespaced: true,
    state: {
        supportMails: [],
        sentMails: []

      
    },

    actions: {

        async getsupportMails({commit}) {
            
            await axios2.get('read-support-emails',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            
            commit('setSupportMails', response.data.emails)
          }).catch((error) => {
            console.log(error)
          })
        },


        async deletesupportMail({commit},ids){
          
            console.log(ids)
            await axios2.post('delete-support-email', {ids: ids}, {
              headers:{
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
              }
            }).then(() => {
                commit('removeSupportMail', ids)
            }).catch((error) => {
              console.log(error)
            })
        
         
        },
    
        async starMail({commit},ids){
         
            
            await axios2.post('star-mail', {ids: ids},  {
              headers:{
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
              }
            }).then(() => {

              commit('starEmail',ids)

            }).catch((err) => {
              console.log(err)
            })
          
         
        },
    
        async unStarMail({commit},ids){
          
              await axios2.post('unStar-mail', {ids: ids}, {
                headers:{
                  'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
                }
              }).then(() => {
                commit('unStarEmail',ids)
            }).catch((err) => {
              console.log(err)
            })
       
          
        },

        async markMailRead({commit},id){
          await axios2.post('mark-read/'+id,null,{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then(() => {
            commit('markRead',id)
          }).catch((err) => {
            console.log(err)
          })
        },

        async getSentMails({commit}){
          await axios2.get('read-email',{
            headers:{
              'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
            }
          }).then((response) => {
            console.log('sent mail get',response)
            commit('setMails',response.data.emails)
          }).catch((err) => {
            console.log(err)
          })
        }
    
    
      },
    
      mutations : {
    
        setSupportMails(state, value) {
          state.supportMails = value
        },

        setMails(state,value){
          state.sentMails = value
        },
    
        removeSupportMail(state,ids){

          ids.forEach(id => {
            let index = state.supportMails.findIndex(mail=> mail._id === id)
            state.supportMails[index].trash = true
            state.supportMails[index].starred = false
          });
        },
    
        starEmail(state,ids){
          
          ids.forEach(id => {
            let index = state.supportMails.findIndex(mail=> mail._id === id)
          state.supportMails[index].starred = true
          })
        },
    
        unStarEmail(state,ids){

          ids.forEach(id => {
            let index = state.supportMails.findIndex(mail=> mail._id === id)
          state.supportMails[index].starred = false
          })
        },

        markRead(state,id){
          let index = state.supportMails.findIndex(mail => mail._id === id)
          state.supportMails[index].status = 'read' 
        }
      },
    
      getters : {
    
        supportMailsGetter(state) {
          let mails = state.supportMails.filter(mail => !mail.trash && !mail.sent_mail)
          return  [...mails].reverse()
          
        },

        starEmailsGetter(state) {
            let mails = state.supportMails.filter(mail => mail.starred && !mail.trash)
            return [...mails].reverse()
        },

        repliesMailsGetter(state){
          let mails =  state.supportMails.filter(mail => !mail.trash && mail.sent_mail)
          return [...mails].reverse()
        },

        trashMailsGetter(state){
          let mails = state.supportMails.filter(mail => mail.trash)
          return [...mails].reverse()
        },

        supportMailGetter: (state) => (id) => {
          return state.supportMails.find(mail => mail._id === id)
        },

        sentMailsGetter(state){
          let mails = state.sentMails
          return [...mails].reverse()
        },
        sentMailGetter: (state) => (id) => {
          return state.sentMails.find(mail => mail._id === id)
        }

      }
    
}