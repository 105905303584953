<template>
  <Layout>
    <div>
      <PageHeader :title="title" :items="items" />
      <div class="row">
        <!-- Right Sidebar -->
        <div class="col-12">
          <mailSideBar />
          <div class="email-rightbar mb-3">
            <div class="card">
              <div class="btn-toolbar p-3">
                <Toolbar />
              </div>

              <div class="mt-3">
                <ul class="message-list">

                  <li v-for="(email, index) in mails" :key="index" :class="{ 'unread': `${email.status}` === 'unread' }">
                    <div class="col-mail col-mail-1">
                      <div class="checkbox-wrapper-mail">
                        <input :id="`chk-${index}`" type="checkbox" />
                        <label :for="`chk-${index}`"></label>
                      </div>
                      <a @click="starFunction(email)"><span class="fa-star"
                          :class="{ 'fas fa-star': email.starred === true, 'far fa-star': email.starred === false }"></span></a>
                      <a @click="deleteEmail(email._id)"><span class='fas fa-trash' style="margin-left: 5px;"></span></a>
                      <a class="title" href="javascript:void(0);" v-on:click="$router.push({path:'/email/reademail/'+email._id+'/'+$route.name,query:{href:$route.path}})">{{ email.email }}</a>
                    </div>
                    <div class="col-mail col-mail-2">
                      <a class="subject" href="javascript:void(0);" v-on:click="$router.push({path:'/email/reademail/'+email._id+'/'+$route.name,query:{href:$route.path}})">{{ email.subject }}</a>
                      <div class="date">{{ convertDate(email.createdAt) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-md-between align-items-md-center">
              <div class="col-xl-7">Showing {{ startIndex }} - {{ endIndex }} of {{ rows }}</div>
              <!-- end col-->
              <div class="col-xl-5">
                <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    @input="onPageChange"></b-pagination>
                </div>
              </div>
              <!-- end col-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>

import mailSideBar from "@/components/email-components/mailSideBar";
import Toolbar from "@/components/email-components/toolbar";
import PageHeader from "@/components/page-header";
import Layout from "../../layouts/main";



export default {
  page: {
    title: "Inbox",
    meta: [{ name: "description" }]
  },
  components: { Layout, PageHeader, mailSideBar, Toolbar },
  data() {
    return {

      paginatedEmailData: null,
      title: "Inbox",
      items: [
        {
          text: "Email",
          href: "/"
        },
        {
          text: "Inbox",
          active: true
        }
      ],

      // page number
      currentPage: 1,
      // default page size
      perPage: 15,

      // start and end index
      startIndex: 1,
      endIndex: 15,
      showModal: false,
    };
  },
  computed: {
    mails() {
      return this.$store.getters['emails/supportMailsGetter']
    },

    rows() {
      return this.mails.length;
    }
  },

  created() {
    this.startIndex = 0;
    this.endIndex = this.perPage;

    this.paginatedEmailData = this.mails.slice(
      this.startIndex,
      this.endIndex
    );
  },
  methods: {
    onPageChange() {
      this.startIndex = (this.currentPage - 1) * this.perPage;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      this.paginatedEmailData = this.mails.slice(
        this.startIndex,
        this.endIndex
      );
    },

    convertDate(date) {
      let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return newDate
    },

    deleteEmail(id) {
      let ids = [id]
      this.$store.dispatch('emails/deletesupportMail', ids)
    },

    starFunction(email) {
      if (email.starred === false) {
        let ids = [email._id]
        this.$store.dispatch('emails/starMail',ids)
      }
      else {
        let ids = [email._id]
        this.$store.dispatch('emails/unStarMail', ids)
      }

    }
  },



  mounted() {
    this.$store.dispatch('emails/getsupportMails')

  }
};
</script>

<style scoped></style>