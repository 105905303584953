<template>
  <layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h4>Transaction Detail</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Driver</th>
                    <td class="text-right">{{ driver.first_name }} {{ driver.last_name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rider</th>
                    <td class="text-right">{{ rider.first_name }} {{ rider.last_name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Transaction Date</th>
                    <td class="text-right">{{ convertDate(transaction.transaction_date) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rider Card Type</th>
                    <td class="text-right">{{ transaction.rider_card_brand }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Amount</th>
                    <td class="text-right">${{ transaction.price }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Rydelinx Commission</th>
                    <td class="text-right">${{ transaction.commission }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h4>Ride Detail</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Rider</th>
                    <td class="text-right">{{ rider.first_name }} {{ rider.last_name }} </td>
                  </tr>
                  <tr>
                    <th scope="row">Driver</th>
                    <td class="text-right">{{ driver.first_name }} {{ driver.last_name }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Booking</th>
                    <td class="text-right">{{ convertDate(ride.createdAt) }}  {{ convertTime(ride.createdAt) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Pick Up</th>
                    <td class="text-right">{{ convertDate(ride.pick_up_at) }}  {{ convertTime(ride.pick_up_at) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Pickup Address</th>
                    <td class="text-right">{{ ride.pick_up_address }}</td>
                  </tr>
                  <tr>
                    <th scope="row">DropOff Address</th>
                    <td class="text-right">{{ ride.drop_off_address }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Canceled</th>
                    <td class="text-right">{{ convertCancelStatus(ride.canceled) }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Canceled By</th>
                    <td class="text-right">{{ ride.canceledBy }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>

  </layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

export default {
  page: {
    title: "Transaction Details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      rider: null,
      driver: null,
      ride: null,
      transaction: null,
      title: "Transaction Details",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Transactions",
          href: "JavaScript:void(0)"
        },
        {
          text: "Transactions Details",
          active: true
        }
      ],
    }
  },


  async beforeMount() {

    await axios.get('payment-by-id/' + this.$route.params.id, {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((res) => {
      this.transaction = res.data.payment
    }).catch((err) => {
      console.log(err)
    })

    axios.get('read-ride-by-id/' + this.transaction.ride_id, {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((res) => {
      this.ride = res.data.ride
    }).catch((err) => {
      console.log(err)
    })

    axios.get('read-user/' + this.transaction.driver_id, {
      params: { userType: 'driver' },
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((res) => {
      this.driver = res.data.driver
    }).catch((err) => {
      console.log(err)
    })

    axios.get('read-user/' + this.transaction.rider_id, {
      params: { userType: 'rider' },
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((res) => {
      this.rider = res.data.rider
    }).catch((err) => {
      console.log(err)
    })
  },

  beforeRouteLeave(to, from, next) {
   
    if (this.$vnode.data.keepAlive) {
      this.$destroy(); 
    }

    next();
  },

  methods: {
    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },
    convertCancelStatus(status) {
      if (status == true) {
        return "Yes"
      } else {
        return "No"
      }
    }


  }
}
</script>

<style scoped>
.card-header {
  background-color: white !important;
}
</style>