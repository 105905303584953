import axios from "axios"

export default {
    namespaced:true,
    state:{
        vehicles:[]
      },

  actions: {

    getVehicles({commit},id){
      axios.get('read-vehicles',{params:{driver_id:id},
      headers:{
          'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
        }}).then((res)=>{
          commit('setVehicles',res.data.vehicles)
        
        }).catch((err)=>{
          console.log(err)
        })
    },

    verifyVehicle({commit},id){
      commit('vehicleVerify',id)
    },

    verifyRegistration({commit},id){
      commit('registrationVerify',id)
    },

    rejectVehicle({commit},id){
      commit('vehicleReject',id)
    },

    rejectRegistration({commit},id){
      commit('registrationReject',id)
    },

  },
  mutations:{

    setVehicles(state,value){
      state.vehicles=value
    },

    vehicleVerify(state,id){
      let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
      state.vehicles[index].vehicle_status= 'verified'
    },

    registrationVerify(state,id){
      let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
      state.vehicles[index].registration_status= 'verified'
    },

    vehicleReject(state,id){
      let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
      state.vehicles.splice(index,1)
    },

    registrationReject(state,id){
      let index = state.vehicles.findIndex(vehicle=>vehicle._id===id)
      state.vehicles[index].registration_status= 'not_uploaded'
      }
    

  },
  getters:{
      vehiclesGetter(state){
        return state.vehicles
      }
  }
}