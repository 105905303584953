<script>

import axios from "axios";


/**
 * Transactions component
 */
export default {
  data() {

    return {
      riders: null,
      drivers: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "createdAt", label: 'Date', sortable: true },
        { key: "rider_id", label: 'Rider', sortable: true },
        { key: "driver_id", label: 'Driver', sortable: true },
        { key: "price", label: 'Total Amount', sortable: true },
        { key: "commission", label: 'Rydelinx Commission', sortable: true },
        { key: "driver_funds", label: 'Driver funds', sortable: true },
        { key: "status", sortable: true },
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    payments() {
      return this.$store.getters['transactions/paymentsGetter']
    },
    rows() {
      return this.payments.length;
    }
  },
  mounted() {

    axios.get('read-riders', {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((res) => {
      this.riders = res.data.riders
    }).catch((err) => {
      console.log(err)
    })

    axios.get('read-drivers', {
      headers: {
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
      }
    }).then((response) => {
      
      this.drivers = response.data.drivers
    }).catch((error) => {
      console.log(error)
    })

    this.$store.dispatch('transactions/getPayments')
    // Set the initial number of items
    this.totalRows = this.payments.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    findRider(id) {
      if (id) {
        let myRider = this.riders.find(obj => obj._id === id)
        if (myRider) {
          return `${myRider.first_name} ${myRider.last_name}(${myRider.phone_number})`
        }
      }
    },
    findDriver(id) {
      if (id) {
        let myDriver = this.drivers.find(obj => obj._id === id)
        if (myDriver) {
          return `${myDriver.first_name} ${myDriver.last_name}(${myDriver.phone_number})`
        }
      }
    },

    convertDate(date) {
      if (date) {
        let newDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return newDate
      }
    },

    convertTime(time) {
      if (time) {
        let newTime = new Date(time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        return newTime
      }
    },
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">

      <h4 class="card-title font-size-20 mb-4"><strong> Latest Transactions</strong></h4>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table :items="payments" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
          @filtered="onFiltered">
          <template v-slot:cell(status)="row">
            <div class="badge font-size-14"
              :class="{ 'badge-soft-success': `${row.value}` === 'payment-received', 'badge-soft-warning': `${row.value}` === 'refunded' }">
              {{ row.value }}
            </div>
          </template>

          <template v-slot:cell(createdAt)="date">
            {{ convertDate(date.value) }}
          </template>

          <template v-slot:cell(rider_id)="rider">
            {{ findRider(rider.value) }}
          </template>

          <template v-slot:cell(driver_id)="driver">
            {{ findDriver(driver.value) }}
          </template>

          <template v-slot:cell(price)="price">
            ${{ price.value }}
          </template>

          <template v-slot:cell(commission)="commission">
            ${{ commission.value }}
          </template>

          <template v-slot:cell(driver_funds)="funds">
            ${{ funds.value.toFixed(2) }}
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
