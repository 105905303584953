<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Riders",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Riders",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Riders",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "first_name", label: 'First Name', sortable: true },
        { key: "last_name", label: 'Last Name', sortable: true },
        { key: "email_address", label: 'Email', sortable: true },
        { key: "phone_number", label: 'Phone', sortable: true },
        { key: "device_type", label: 'Device Type', sortable: true },
        { key: "action" }
      ],
      data_fields:{
        "First Name": "first_name",
        "Last Name": "last_name",
        "Phone Number": "phone_number",
        "Email Address": "email_address",
        "Device Type": "device_type"
      },
    };
  },

  watch:{
    rows: function (val){
      this.totalRows = val
    }
  },

  computed: {
    /**
     * Total no. of records
     */
    riders() {
      return this.$store.getters['riders/ridersGetter'];
    },

    rows() {
      return this.riders.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.$store.dispatch('riders/getRiders')
    this.totalRows = this.riders.length;

  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h4 class="card-title font-size-20"><strong> All Riders</strong></h4>
              <download-excel :data="riders" :fields="data_fields">
                <b-button variant="primary">Export Riders</b-button>
              </download-excel>
            </div>
            <div class="row mt-4">
              <div class="col-sm-6 col-md-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>

              <div class="col-sm-6 col-md-2 d-inline-flex" style="font-weight: 600;margin-top: 4px;">
                No. of Riders: {{ totalRows }}
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-7">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="riders" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered">

                <template v-slot:cell(action)="{ item }">
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Details" @click="$router.push({ path: `/riders/${item._id}` })">
                    <i class="ri-folder-shared-fill font-size-24"></i>
                  </a>
                  <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                    title="Rides" @click="$router.push({ path: `/riders/rider-rides/${item._id}` })">
                    <i class="ri-taxi-wifi-fill font-size-24"></i>
                  </a>
                </template>
              </b-table>
            </div>

            <div class="row " v-show="riders.length === 0">
              <div class="col text-center">
                <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>



  </Layout>
</template>