<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import appConfig from "@/app.config.json";
import StarRating from "vue-star-rating";
import vehicles from '@/components/documents-components/vehicles.vue'
import vehcleRegistration from '@/components/documents-components/registrations.vue'



export default {
  page: {
    title: "Drivers",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, StarRating, vehicles, vehcleRegistration },
  data() {
    return {
      // driver: null,
      star: null,
      isPersonalInsurance: false,
      isCommercialInsurance: false,
      rejectMessage: null,
      title: "Driver Documents",
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Drivers",
          href: "JavaScript:void(0)"
        },
        {
          text: "Driver Documents",
          active: true
        }
      ],
    }
  },

  mounted() {
    // axios.get('read-user/' + this.$route.params.id, {
    //   params: { userType: 'driver' },
    //   headers: {
    //     'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
    //   }
    // }).then((res) => {
    //   console.log('drivre', res.data)
    //   this.driver = res.data.driver
    // }).catch((err) => {
    //   console.log(err)
    // })
    this.$store.dispatch('driver/getDriversforDetail')

    if (this.driver.rating === 0) {
      this.star = 0
    } else {
      this.star = this.driver.rating / this.driver.completed_rides
    }

  },

  computed: {
    driver() {
      return this.$store.getters['driver/driverGetter'](this.$route.params.id)
    }
  },

  methods: {
    verify(documentName) {

      axios.post('verify-documents/' + this.driver._id, { document: documentName, }, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        }
      }).then((res) => {
        console.log(res)
        if (documentName === 'driver_license') {
          this.driver.driver_license.status = 'verified'
        }
        else if (documentName === 'commercial_insurance') {
          this.driver.insurance.commercial_insurance_status = 'verified'
        }
        else if (documentName === 'personal_insurance') {
          this.driver.insurance.personal_insurance_status = 'verified'
        }
        else if (documentName === 'security_information') {
          this.driver.security_information.status = 'verified'
        }
        else if (documentName === 'bank_information') {
          this.driver.bank_information.status = 'verified'
        }
        this.$bvToast.toast('Document Verified', {
          variant: 'success',
          solid: true
        })
      }).catch((err) => {
        console.log(err)
      })
    },

    reject(documentName) {
      if (this.rejectMessage) {
        axios.post('reject-documents/' + this.driver._id, { document: documentName, message: this.rejectMessage }, {
          headers: {
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
          }
        }).then(() => {
          this.rejectMessage = null
          if (documentName === 'driver_license') {
            this.driver.driver_license.status = 'not-uploaded'
          }
          else if (documentName === 'commercial_insurance') {
            this.driver.insurance.commercial_insurance_status = 'not-uploaded'
            this.driver.insurance.commercial_insurance_url = null
          }

          else if (documentName === 'personal_insurance') {
            this.driver.insurance.personal_insurance_status = 'not-uploaded'
            this.driver.insurance.personal_insurance_url = null
          }

          else if (documentName === 'bank_information') {
            this.driver.bank_information.status = 'not-uploaded'
          }
          this.$bvToast.toast('Document Rejected', {
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          console.log(err)
        })
      }
      else {
        this.$bvToast.toast('Please Enter Rejection Message first to Reject Document', {
          variant: 'danger',
          solid: true
        })
      }

    },

    notify(documentName) {
      console.log(documentName)
      axios.post('notify-documents/' + this.driver._id, { document: documentName }, {
        headers: {
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
        }
      }).then(() => {
        this.$bvToast.toast('Notification sent', {
          variant: 'success',
          solid: true
        })
      }).catch((error) => {
        console.log(error)
      })

    }

  },
}



</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="card profile-card">
      <div class="card-body">
        <img alt="200x200" v-if="driver.photo_url !== null" :src="driver.photo_url" data-holder-rendered="true"
          class="img-thumbnail rounded-circle avatar-xl">
        <img alt="200x200" v-if="driver.photo_url === null" src='../../../assets/images/users/dummy-avatar.jpg'
          data-holder-rendered="true" class="img-thumbnail rounded-circle avatar-xl">

        <div class="profile-text">
          <h5 class="mb-0">{{ driver.first_name }} {{ driver.last_name }}</h5>
          <div class="stars">
            <div class="text-center">
              <star-rating :rating=star :read-only="true" :star-size="15"></star-rating>
            </div>
          </div>
        </div>
        <!-- <p :class="{'text-success profile-status':driver.status.active_status === 'active','text-danger profile-status':driver.status.active_status === 'inactive' }"><i class="ri-checkbox-blank-circle-fill"></i> {{ driver.status.active_status }}</p> -->
        <!--        <a href="javascript: void(0);" target="_self" class="btn btn-danger profile-button">De-Activate</a>-->
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Documents</h4>
            <!-- Table -->
            <div class="table table-responsive-lg mb-0">
              <table>
                <thead>
                  <tr>
                    <th scope="col" style="width: 25vw">Documents</th>
                    <th scope="col" style="width: 25vw">Status</th>
                    <th scope="col" style="width: 25vw">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Driver License</td>
                    <td>{{ driver.driver_license.status }}</td>
                    <td v-if="driver.driver_license.status !== 'not-uploaded'">
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover v-b-modal.license-modal
                        data-toggle="tooltip" title="View">
                        <i class=" ri-eye-line font-size-24"></i>
                      </a>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Verify" @click="verify('driver_license')">
                        <i class="ri-checkbox-circle-fill font-size-24" style="color: #1cbb8c"></i>
                      </a>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Reject" v-b-modal.reject-license>
                        <i class=" ri-close-circle-fill font-size-24" style="color: #ff3d60"></i>
                      </a>
                    </td>

                    <td v-if="driver.driver_license.status === 'not-uploaded'">
                      <b-button variant="primary" v-on:click="notify('driver_license')">Notify</b-button>
                    </td>


                  </tr>

                  <tr
                    v-if="driver.insurance.personal_insurance_url !== null || driver.insurance.personal_insurance_status !== 'not-uploaded'">
                    <td>Personal Insurance</td>
                    <td>{{ driver.insurance.personal_insurance_status }}</td>
                    <td>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover
                        @click="$bvModal.show('personal-insurance-modal')" data-toggle="tooltip" title="View">
                        <i class=" ri-eye-line font-size-24"></i>
                      </a>

                      <b-modal id="personal-insurance-modal" title="Personal Insurance" title-class="font-18" ok-only>
                        <img :src="driver.insurance.personal_insurance_url" style=" width: 100%">
                      </b-modal>

                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Accept" @click="verify('personal_insurance')">
                        <i class="ri-checkbox-circle-fill font-size-24" style="color: #1cbb8c"></i>
                      </a>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Reject" v-b-modal.reject-personal-insurance>
                        <i class=" ri-close-circle-fill font-size-24" style="color: #ff3d60"></i>
                      </a>
                    </td>
                    <!-- <td v-if="insurance.personal_driving_record_status === 'not-uploaded' || insurance.personal_driving_record_url === null">
                    <b-button variant="primary" v-on:click="notify('personal_driving_record')">Notify</b-button>
                  </td> -->
                  </tr>

                  <tr
                    v-if="driver.insurance.commercial_insurance_url !== null || driver.insurance.commercial_insurance_status !== 'not-uploaded'">
                    <td>Commercial Insurance</td>
                    <td>{{ driver.insurance.commercial_insurance_status }}</td>
                    <td>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover
                        @click="$bvModal.show('commercial-insurance-modal')" data-toggle="tooltip" title="View">
                        <i class=" ri-eye-line font-size-24"></i>
                      </a>

                      <b-modal id="commercial-insurance-modal" title="Commercial Insurance" title-class="font-18" ok-only>
                        <img :src="driver.insurance.commercial_insurance_url" style=" width: 100%">
                      </b-modal>

                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Accept" @click="verify('commercial_insurance')">
                        <i class="ri-checkbox-circle-fill font-size-24" style="color: #1cbb8c"></i>
                      </a>
                      <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip"
                        title="Reject" v-b-modal.reject-commercial-insurance>
                        <i class=" ri-close-circle-fill font-size-24" style="color: #ff3d60"></i>
                      </a>
                    </td>

                    <!-- <td v-if="insurance.personal_criminal_record_status === 'not-uploaded' || insurance.personal_criminal_record_url === null">
                    <b-button variant="primary" v-on:click="notify('personal_criminal_record')">Notify</b-button>
                  </td> -->
                  </tr>

                  <tr
                    v-if="(driver.insurance.personal_insurance_url === null || driver.insurance.personal_insurance_status === 'not-uploaded') && (driver.insurance.commercial_insurance_url === null || driver.insurance.commercial_insurance_status === 'not-uploaded')">
                    <td>Insurance</td>
                    <td>not-Uploaded</td>
                    <td>
                      <b-button variant="primary" v-on:click="notify('insurance')">Notify</b-button>
                    </td>
                  </tr>

                  <tr>
                    <td>Bank Information</td>
                    <td>{{ driver.bank_information.status }}</td>
                    <td v-if="driver.bank_information.status === 'not-uploaded'">
                      <b-button variant="primary" v-on:click="notify('bank_information')">Notify</b-button>
                    </td>
                  </tr>

                </tbody>

              </table>

            </div>
          </div>
        </div>
      </div>

      <b-modal id="license-modal" title="Driver license" title-class="font-18" ok-only>
        <img :src="driver.driver_license.url" style=" width: 100%">
      </b-modal>





      <b-modal id="reject-license" title="License Reject Message" title-class="font-18" ok-title="Reject"
        @ok="reject('driver_license')" @cancel="rejectMessage = null">
        <textarea v-model="rejectMessage" class="form-control" placeholder="Enter Reject Message"></textarea>
      </b-modal>

      <b-modal id="reject-commercial-insurance" title="Commerical Insurance Reject Message" title-class="font-18"
        ok-title="Reject" @ok="reject('commercial_insurance')" @cancel="rejectMessage = null">
        <textarea v-model="rejectMessage" class="form-control" placeholder="Enter Reject Message"></textarea>
      </b-modal>

      <b-modal id="reject-personal-insurance" title="Personal Insurance Reject Message" title-class="font-18"
        ok-title="Reject" @ok="reject('personal_insurance')" @cancel="rejectMessage = null">
        <textarea v-model="rejectMessage" class="form-control" placeholder="Enter Reject Message"></textarea>
      </b-modal>



    </div>

    <vehicles />
    <vehcleRegistration />



  </Layout>
</template>

<style scoped>
.profile-card {
  position: relative;
}

.profile-text {
  position: absolute;
  top: 36%;
  left: 165px;
}

.profile-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.profile-status {
  position: absolute;
  top: 10px;
  right: 10px;
}

.profile-status i {
  font-size: 12px !important;
}

.icon-text {
  font-size: 18px;
  margin-bottom: 25px;
}

.document-card {
  position: relative;
}

.card-text {
  position: absolute;
  top: 15px;
  right: 15px;
  text-overflow: ellipsis;
  max-width: 170px;
}

.card-text i {
  margin-right: 3px;
}

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  .card-text {
    font-size: 14px;
  }
}
</style>