<script>
import axios from "axios";


/**
 * Advanced table component
 */
export default {
 
  data() {
    return {
      vehicleId:null,
      registrationRejectId:null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5,10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "car_name",label:'Vehicle Name', sortable: true },
        { key: "plate_number",label:'Plate Number', sortable: true },
        { key: "make",label:'Make', sortable: true },
        { key: "model",label:'Model', sortable: true },
        { key: "registration_status",label:'Registration Status', sortable: true },
        { key: "action" }
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
     vehicles(){
      return this.$store.getters['vehicles/vehiclesGetter']
    },
   
    rows() {
      
      return this.vehicles.length;
    }
  },

  mounted(){

    this.$store.dispatch('vehicles/getVehicles',this.$route.params.id)
    this.totalRows = this.vehicles.length;
  },

  watch:{
    '$route.params.id'(newVal,oldVal){
      console.log(oldVal)
      if(this.$route.path.includes('document')){
        this.$store.dispatch('vehicles/getVehicles', newVal)
      }
    }
  },


  methods: {

    verifyRegistration(documentName,id){
      axios.post('verify-documents/'+this.$route.params.id, { document: documentName,vehicle_id:id},{
        headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
      }).then((res)=>{
        console.log('Response',res)
        this.$store.dispatch('vehicles/verifyRegistration',id)
        this.$bvToast.toast('Verified',{
          variant:'success',
          solid:true
        })
      }).catch((err)=>{
        console.log('error',err)
      })
    },

    rejectRegistration(documentName,id){

      if(this.rejectMessage){
        axios.post('reject-documents/'+this.$route.params.id, { document: documentName, vehicle_id: id, message:this.rejectMessage},{
        headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
      }).then((res)=>{
        console.log('Response',res)
        this.rejectMessage=null
        this.$store.dispatch('vehicles/rejectRegistration',id)
        this.$bvToast.toast('Rejected',{
          variant:'success',
          solid:true
        })

      }).catch((err)=>{
        console.log('error',err)
      })
      }
      else{
        this.$bvToast.toast('Please enter rejection message first to reject vehicle registration',{
          variant:'danger',
          solid:true
        })
      }

    },

    notifyRegistration(documentName,vehicleId){
      axios.post('notify-documents/'+this.$route.params.id,{ document: documentName,vehicle_id:vehicleId},{
        headers:{
        'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('user')).token
      }
      }).then((response)=>{
        console.log(response)
        this.$bvToast.toast('Notification sent',{
          variant:'success',
          solid:true
        })
      }).catch((error)=>{
        console.log(error)
      })
        
    },


   
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

  },

};
</script>

<template>

  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Vehicle Registrations</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  :items="vehicles"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template v-slot:cell(car_name)="{item}">
                  <div> {{item.make}} {{item.model}} </div>
                </template>
                <template v-slot:cell(action)="{item}">
                  <div v-if="item.registration_status !== 'not_uploaded' ">
                    <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover v-b-modal.vehicleRegistration @click="vehicleId=item._id" data-toggle="tooltip" title="View" >
                      <i class=" ri-eye-line font-size-24"></i>
                    </a>

                    <b-modal id="vehicleRegistration" v-if="vehicleId===item._id" title="Registration" title-class="font-18" ok-only>
                      <img :src="item.url" style=" width: 100%">
                    </b-modal>

                    <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip" title="Accept" @click="verifyRegistration('vehicle_registration',item._id)">
                      <i class="ri-checkbox-circle-fill font-size-24" style="color: #1cbb8c"></i>
                    </a>
                    <a class="mr-3 text-primary" href="javascript:void(0);" v-b-tooltip.hover data-toggle="tooltip" v-b-modal.reject-registration title="Reject" @click="registrationRejectId = item._id">
                      <i class=" ri-close-circle-fill font-size-24" style=" color: #ff3d60"></i>
                    </a>
                  </div>
                  <div v-if="item.registration_status === 'not_uploaded' ">
                    <b-button variant="primary" v-on:click="notifyRegistration('vehicle_registration',item._id)">Notify</b-button>
                  </div>
                </template>


              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

            <b-modal id="reject-registration" title="Reject Vehicle Message" title-class="font-18" ok-title="Reject" @ok="rejectRegistration('vehicle_registration', registrationRejectId)">
              <textarea v-model="rejectMessage" class="form-control" placeholder="Enter Reject Message"></textarea>
            </b-modal>


          </div>
        </div>
      </div>

    </div>
  </div>
</template>