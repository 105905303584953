import axios from 'axios';

const axios1 = axios.create({
    baseURL: 'https://live.rydelinx.com/api/admin/auth/'
  })

class AuthService {
  login(user) {
    return axios1.post('login', { email: user.email, password: user.password }).then((response) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios1.post('register', {firstName: user.firstName, lastName: user.lastName,email: user.email, phoneNumber:user.phoneNumber,password: user.password });
  }
}

export default new AuthService(); 